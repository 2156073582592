import { defineAsyncComponent } from 'vue'
import Multiselect from 'vue-multiselect'
import RelativeTime from '../../components/common/relative-time.vue'

export function setupGlobalComponents (app) {
  app.component('Multiselect', Multiselect)
  app.component('RelativeTime', RelativeTime)

  app.component('AuthSocialNav', defineAsyncComponent(() => import('../../components/auth/social-nav.vue')))
  app.component('AuthRegister', defineAsyncComponent(() => import('../../components/auth/register/register.vue')))
  app.component('MessageBubble', defineAsyncComponent(() => import('../../components/message-bubble.vue')))

  app.component('VideoShow', defineAsyncComponent(() => import('../../components/video/show.vue')))
  app.component('VideoShowReply', defineAsyncComponent(() => import('../../components/video/show-reply.vue')))
  app.component('VideoAppShare', defineAsyncComponent(() => import('../../components/video/app-share.vue')))
  app.component('VideoEmbed', defineAsyncComponent(() => import('../../components/video/embed.vue')))
  app.component('VideoEmbedCreateWrapper', defineAsyncComponent(() => import('../../components/video/embed-create-wrapper.vue')))
  app.component('VideoCreateWithPermissionModal', defineAsyncComponent(() => import('../../components/video/create-with-permission-modal.vue')))
  app.component('VideoPasswordForm', defineAsyncComponent(() => import('../../components/video/password-form.vue')))
  app.component('VideoPortfolio', defineAsyncComponent(() => import('../../components/video/portfolio.vue')))

  app.component('ShowcaseShow', defineAsyncComponent(() => import('../../components/showcase/show.vue')))
  app.component('ShowcaseEmbed', defineAsyncComponent(() => import('../../components/showcase/embed.vue')))

  app.component('FormRender', defineAsyncComponent(() => import('../../components/form/render.vue')))
  app.component('BootModal', defineAsyncComponent(() => import('../../components/common/boot-modal.vue')))
  app.component('ForceRouterLink', defineAsyncComponent(() => import('../../components/common/force-router-link.vue')))
  app.component('VideoJs', defineAsyncComponent(() => import('../../components/common/video-js.vue')))
  app.component('VideoPreviewList', defineAsyncComponent(() => import('../../components/common/video-preview-list.vue')))
  app.component('WorldMapJoin', defineAsyncComponent(() => import('../../components/common/world-map-join.vue')))
  app.component('FeatureLocker', defineAsyncComponent(() => import('../../components/common/feature-locker.vue')))
  app.component('ExitIntent', defineAsyncComponent(() => import('../../components/common/exit-intent.vue')))
  app.component('EmptyTableMessage', defineAsyncComponent(() => import('../../components/common/empty-table-message.vue')))
  app.component('Paginator', defineAsyncComponent(() => import('../../components/common/paginator.vue')))
  app.component('V4Navbar', defineAsyncComponent(() => import('../../components/common/v4-navbar.vue')))
  app.component('RichTooltip', defineAsyncComponent(() => import('../../components/rich-tooltip.vue')))

  app.component('LandingPageBuilderGrapesjs', defineAsyncComponent(() => import('../../components/landing-page/grapesjs-builder.vue')))
  app.component('LandingPageBuilderUnlayer', defineAsyncComponent(() => import('../../components/landing-page/unlayer-builder.vue')))

  app.component('GuestVideoCreate', defineAsyncComponent(() => import('../../components/guest-video/create.vue')))
  app.component('GuestVideoShow', defineAsyncComponent(() => import('../../components/guest-video/show.vue')))
  app.component('GuestVideoEdit', defineAsyncComponent(() => import('../../components/guest-video/edit.vue')))

  app.component('EmailTemplateBuilderGrapesjs', defineAsyncComponent(() => import('../../components/email-template/grapesjs-builder.vue')))
  app.component('EmailTemplateBuilderUnlayer', defineAsyncComponent(() => import('../../components/email-template/unlayer-builder.vue')))

  app.component('VideoTemplateBuilder', defineAsyncComponent(() => import('../../components/video-template/builder.vue')))
  app.component('VideoSection', defineAsyncComponent(() => import('../../components/video-template/video-section.vue')))
  app.component('VideoTitle', defineAsyncComponent(() => import('../../components/video-template/video-title.vue')))
  app.component('VideoDescription', defineAsyncComponent(() => import('../../components/video-template/video-description.vue')))
  app.component('CtaSection', defineAsyncComponent(() => import('../../components/video-template/cta-section.vue')))
  app.component('ContactSection', defineAsyncComponent(() => import('../../components/video-template/contact-section.vue')))
  app.component('FormSection', defineAsyncComponent(() => import('../../components/video-template/form-section.vue')))
  app.component('CalendarSection', defineAsyncComponent(() => import('../../components/video-template/calendar-section.vue')))

  app.component('TeamSubscription', defineAsyncComponent(() => import('../../components/team-subscription/index.vue')))

  app.component('PagesMarketplace', defineAsyncComponent(() => import('../../components/pages/marketplace.vue')))
}
