import { DateTime } from 'luxon'

import { number_with_commas } from '../../utils'

/**
 * Format the given date.
 */
function date (value, zone = null) {
  if (!value) {
    return ''
  }

  return DateTime
    .fromFilterValue(value)
    .setZone(zone || Spark.getTimezone())
    .toCustomLocaleString(DateTime.CUSTOM_DATE_FULL)
}

/**
 * Format the given date as a timestamp.
 */
function datetime (value, zone = null) {
  if (!value) {
    return ''
  }

  return DateTime
    .fromFilterValue(value)
    .setZone(zone || Spark.getTimezone())
    .toCustomLocaleString(DateTime.CUSTOM_DATETIME_MED)
}

/**
 * Convert the first character to upper case.
 *
 * Source: https://github.com/vuejs/vue/blob/1.0/src/filters/index.js#L37
 */
function capitalize (value) {
  if (!value && value !== 0) {
    return ''
  }

  return value.toString().charAt(0).toUpperCase() +
    value.slice(1)
}

function currency (value, digits = 2) {
  value = parseFloat(value)

  if (!isFinite(value) || (!value && value !== 0)) {
    return ''
  }

  const sign = value < 0 ? '-' : ''

  return sign + '$' + number_with_commas(Math.abs(value))
}

export const globalFilters = {
  date,
  datetime,
  capitalize,
  currency,
  number_with_commas
}
