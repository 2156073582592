/**
 * Created by leo108 on 2019/10/20.
 */
export function setupGlobalMixins (app) {
  // data
  app.config.globalProperties.maps = window.maps
  app.config.globalProperties.constants = window.constants
  // computed
  app.config.globalProperties.hasTeam = Spark.hasTeam()

  app.config.globalProperties.spark = window.Spark
  app.config.globalProperties.router = Spark.router
  app.config.globalProperties.ownsTeam = Spark.ownsTeam
  app.config.globalProperties.teamHasFeature = Spark.teamHasFeature
  app.config.globalProperties.roleHasPermission = Spark.roleHasPermission
  app.config.globalProperties.teamGetQuota = Spark.teamGetQuota
  app.config.globalProperties.teamHasQuota = Spark.teamHasQuota
  app.config.globalProperties.teamHasActiveCoreSubscription = Spark.teamHasActiveCoreSubscription
  app.config.globalProperties.teamOnTrial = Spark.teamOnTrial
  app.config.globalProperties.getTimezone = Spark.getTimezone
  app.config.globalProperties.openTab = url => {
    window.open(url)
  }
}
