import {
  createApp
} from 'vue'
import { router } from '../../router'
import { setupGlobalMixins } from './global-mixins'
import { globalFilters } from './global-filters'
import { setupGlobalComponents } from './global-components'
import { setupSentry } from './sentry'

export function setupApp () {
  if (document.querySelector('#spark-app')) {
    const app = createApp({})

    app.config.globalProperties.$filters = globalFilters
    app.config.globalProperties.user = Spark.state.user
    app.config.globalProperties.currentTeam = Spark.state.currentTeam

    setupGlobalComponents(app)
    setupGlobalMixins(app)

    setupSentry(app, router)

    app.use(router)

    app.mount('#spark-app')

    window.app = app

    return app
  }
}
